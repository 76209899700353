import React from "react";
import {
	makeStyles,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.common.mainGrey,
	},
	footerColumnHeader: {
		...theme.typography.footerTextHeader,
	},
	footerText: {
		...theme.typography.footerText,
		lineHeight: "2em",
	},
	footerColumn: {
		marginRight: "5em",
	},
	item: {
		"&:hover": {
			color: "red",
		},
	},
}));

const footerColumns = [
	{
		header: "שימושי",
		items: [
			{
				label: "דף הבית",
				link: "/",
			},
			{
				label: "מי אנחנו",
				link: "/about",
			},
			{
				label: "סניפי PHR",
				link: "/contact",
			},
			{
				label: "גיוס עובדים",
				link: "/contact",
			},
			{
				label: "משאבי אנוש",
				link: "/about",
			},
			{
				label: "חברת השמה",
				link: "/about",
			},
			{
				label: "יצירת קשר",
				link: "/contact",
			},
		],
	},
	{
		header: "ההתמחות שלנו",
		items: [
			{
				label: "בניית קריירה",
				link: "/contact",
			},
			{
				label: "חיפוש משרה",
				link: "/contact",
			},
			{
				label: "ייעוץ תעסוקתי",
				link: "/contact",
			},
			{
				label: "ייעוץ ניהולי",
				link: "/contact",
			},
			{
				label: "עבודה לחיילים משוחררים",
				link: "/contact",
			},
			{
				label: "עבודה לסטודנטים",
				link: "/contact",
			},
			{
				label: "המשרות שלנו",
				link: "/contact",
			},
		],
	},
	{
		header: "משרות",
		items: [
			{
				label: "כלכלן",
			},
			{
				label: "רואה חשבון",
			},
			{
				label: "מנהל חשבונות",
			},
			{
				label: "אנליסט",
			},
			{
				label: "מנהל לוגיסטיקה",
			},
			{
				label: "מהנדס מערכות מים",
			},
		],
	},
	{
		header: "",
		items: [
			{
				label: "מנהל פרויקט חשמל",
			},
			{
				label: "מנהל מרכז לוגיסטי",
			},
			{
				label: 'משנה למנכ"ל',
			},
			{
				label: "מנהל פרוייקט הגנה ימית",
			},
			{
				label: 'סמנכ"ל תפעול',
			},
			{
				label: "מהנדס תפעול",
			},
			{
				label: "מנהל פרוייקט טכנולוגי",
			},
		],
	},
	{
		header: "",
		items: [
			{
				label: "מטמיע מערכת",
			},
			{
				label: "cyber security expert",
			},
			{
				label: "מטמיע SAP",
			},
			{
				label: "מנהל IT",
			},
			{
				label: "תומך טכני",
			},
			{
				label: "אחראי תקשורת ומערכות מידע",
			},
			{
				label: "בקר תקציב",
			},
		],
	},
	{
		header: "",
		items: [
			{
				label: "חשב שכר",
			},
			{
				label: "מבקר פנים",
			},
			{
				label: "מנהל מכירות",
			},
			{
				label: "מנהל מותג",
			},
			{
				label: "מנהל מערך שירות",
			},
			{
				label: "רכז שירות",
			},
		],
	},
];

const footerColumnsForSmallerScreens = [
	{
		header: "שימושי",
		items: [
			{
				label: "דף הבית",
				link: "/",
			},
			{
				label: "מי אנחנו",
				link: "/about",
			},
			{
				label: "סניפי PHR",
				link: "/contact",
			},
			{
				label: "גיוס עובדים",
				link: "/contact",
			},
			{
				label: "משאבי אנוש",
				link: "/about",
			},
			{
				label: "חברת השמה",
				link: "/about",
			},
			{
				label: "יצירת קשר",
				link: "/contact",
			},
		],
	},
	{
		header: "ההתמחות שלנו",
		items: [
			{
				label: "בניית קריירה",
				link: "/contact",
			},
			{
				label: "חיפוש משרה",
				link: "/contact",
			},
			{
				label: "ייעוץ תעסוקתי",
				link: "/contact",
			},
			{
				label: "ייעוץ ניהולי",
				link: "/contact",
			},
			{
				label: "עבודה לחיילים משוחררים",
				link: "/contact",
			},
			{
				label: "עבודה לסטודנטים",
				link: "/contact",
			},
			{
				label: "המשרות שלנו",
				link: "/contact",
			},
		],
	},
];

export default function Footer(props) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

	let footerColumnsToDisplay = matchesSM
		? footerColumnsForSmallerScreens
		: footerColumns;

	return (
		<Grid container justify="space-evenly" className={classes.root}>
			{footerColumnsToDisplay.map((col, i) => (
				<Grid item key={i}>
					<Grid container direction="column">
						<Grid item>
							<Typography className={classes.footerColumnHeader}>
								{col.header === "" ? <br /> : col.header}
							</Typography>
						</Grid>
						{col.items.map((item, j) => (
							<Grid item key={j}>
								<Typography className={classes.footerText}>
									<a
										href={item.link}
										style={{
											textDecoration: "none",
											color: "inherit",
										}}
									>
										{item.label}
									</a>
								</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}

import React, { useState } from "react";
import {
	makeStyles,
	Grid,
	useTheme,
	useMediaQuery,
	Typography,
	Button,
} from "@material-ui/core";

import bg1 from "../../assets/bg1.jpg";
import interviewPreparation from "../../assets/interviewPreparation.svg";
import recruiters from "../../assets/recruiters.svg";
import ContactComponent from "../ui/ContactComponent";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.common.mainBg,
	},
	rightContainer: {
		backgroundImage: `url(${bg1})`,
		backgroundPositionX: "40%",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		height: "70em",
	},
	mainContainer: {
		[theme.breakpoints.up("md")]: {
			maxWidth: "41em",
		},
		[theme.breakpoints.down("md")]: {
			maxWidth: "35em",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "21em",
		},
	},
	activityFieldContainer: {
		marginTop: "3em",
	},
	header: {
		...theme.typography.pageHeader,
	},
	clickHereText: {
		color: theme.palette.common.orangeColor,
		fontWeight: "700",
	},
	creamText: {
		color: theme.palette.common.creamColor,
	},
	recruitersImage: {
		width: "25em",
		[theme.breakpoints.down("xs")]: {
			width: "14em",
		},
	},
	interviewPreparationImage: {
		[theme.breakpoints.down("xs")]: {
			width: "12em",
		},
	},
}));

export default function ActivityFields(props) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

	const [
		showInterviewPreparationContact,
		setShowInterviewPreparationContact,
	] = useState(false);
	const [showCompanyContact, setShowCompanyContact] = useState(false);

	return (
		<Grid container className={classes.root}>
			<Grid
				item
				xs={matchesSM ? 0 : 3}
				className={classes.rightContainer}
			/>
			<Grid item xs={matchesSM ? 12 : 9}>
				<Grid container direction="column">
					<Grid item className={classes.activityFieldContainer}>
						<Grid
							container
							direction={matchesMD ? "column" : "row"}
							justify={matchesMD ? "center" : "space-around"}
							alignItems="center"
						>
							<Grid item>
								<Grid
									container
									direction="column"
									className={classes.mainContainer}
								>
									<Grid
										item
										className={classes.header}
										style={{
											fontSize: "2.4rem",
										}}
									>
										הכנה לראיון וליווי בתהליך חיפוש עבודה
									</Grid>
									<Grid
										item
										className={classes.mainContainer}
										style={{
											marginTop: "1em",
										}}
									>
										<Typography variant="subtitle1">
											תהליך חיפוש עבודה הוא תהליך מורכב.
											<br />
											אנחנו נמצאים שם עבור המועמד בדיוק
											בנקודת הזמן הקריטית על מנת ללוות
											אותו בתהליך וללכת איתו יד ביד עד
											ההגעה ליעד שהציב לעצמו.
											<br />
											למצא תפקיד שמתאים לניסיון המקצועי,
											להשכלה, לאישיות ולשלב בו הוא נמצא
											במעגל החיים המקצועי.
											<br />
											יש המון משרות והמון אנשים שמחפשים את
											הדבר הבא.
											<br />
											קורות חיים מדויקים והכנה מוקדמת
											לראיון הם קריטיים להצלחת התהליך עבור
											מחפש העבודה.
											<br />
											אלה הדברים אותם נעשה יחד. <br />
										</Typography>
										<Typography
											variant="subtitle1"
											style={{ fontWeight: "700" }}
										>
											<br />
											יהודית ציטר, <br />
											בוגרת לימודי תואר ראשון במדעי
											ההתנהגות, <br />
											תואר שני במנהל עסקים
											<br />
											מוסמכת איק"א – אימון אישי לבניית
											קריירה בעלת ניסיון של 25 שנים
											בתפקידי סמנכ"ל משאבי אנוש, לווי
											מנהלים ועובדים בתהליכי גיוס וחיפוש
											עבודה.
										</Typography>
									</Grid>
									<Grid
										item
										style={{
											marginTop: "1em",
											marginBottom: matchesMD
												? "2em"
												: "0",
											alignSelf: matchesMD
												? "center"
												: "baseline",
										}}
									>
										<Button
											variant="contained"
											color="secondary"
											onClick={() =>
												setShowInterviewPreparationContact(
													!showInterviewPreparationContact
												)
											}
										>
											<Typography
												variant="subtitle1"
												className={
													classes.clickHereText
												}
											>
												לחץ כאן {"\xa0"}
											</Typography>
											<Typography
												variant="subtitle1"
												style={{
													color:
														theme.palette.common
															.creamColor,
												}}
											>
												להמשך ייעוץ לראיונות עבודה
											</Typography>
										</Button>
									</Grid>
								</Grid>
							</Grid>
							{matchesMD && (
								<Grid
									item
									style={{
										alignSelf: "center",
									}}
								>
									{showInterviewPreparationContact && (
										<ContactComponent
											header="ייעוץ והכנה לראיונות עבודה"
											marginBottom="2em"
											reduceHeaderSize="true"
										/>
									)}
								</Grid>
							)}
							<Grid item>
								<img
									src={interviewPreparation}
									alt="interview preparation"
									className={
										classes.interviewPreparationImage
									}
								/>
							</Grid>
						</Grid>
					</Grid>
					{!matchesMD && (
						<Grid
							item
							style={{
								alignSelf: "center",
								width: "35em",
							}}
						>
							{showInterviewPreparationContact && (
								<ContactComponent
									header="ייעוץ והכנה לראיונות עבודה"
									marginTop="2em"
								/>
							)}
						</Grid>
					)}
					<Grid item style={{ marginTop: matchesSM ? "5em" : "7em" }}>
						<Grid
							container
							direction={matchesMD ? "column" : "row"}
							justify={matchesMD ? "center" : "space-around"}
							alignItems="center"
						>
							<Grid item>
								{!matchesMD && (
									<img
										src={recruiters}
										alt="recruiters"
										className={classes.recruitersImage}
									/>
								)}
							</Grid>
							<Grid item>
								<Grid
									container
									direction="column"
									style={{
										maxWidth: matchesXS && "21em",
									}}
								>
									<Grid
										item
										className={classes.header}
										style={{
											fontSize: matchesSM
												? "2rem"
												: "3rem",
										}}
									>
										ועבור החברה המגייסת...
									</Grid>
									<Grid
										item
										style={{
											marginTop: "1em",
											maxWidth: "30em",
										}}
									>
										<Typography variant="subtitle1">
											הכנת המועמד לתהליך חיפוש העבודה טובה
											ומשפרת את תהליך גיוס העובדים גם עבור
											החברה המגייסת.
											<br />
											תהליך הגיוס הופך ממוקד, המועמדים
											מתאימים באמת לתפקיד המוצע ועל כן
											הראיונות איכותיים מהנים ובעיקר
											מביאים לגיוס המועמד המתאים וסגירת
											המשרה בזמן קצר
											<br />
										</Typography>
										<Typography
											variant="subtitle1"
											style={{
												fontWeight: "700",
												textAlign: "center",
												marginTop: "1em",
												marginBottom: "1em",
											}}
										>
											המקום הזה , שאנחנו נמצאים שם בכל רגע
											בתהליך
											<br />
											גם עבור החברה וגם עבור המועמדים
											<br />
											מייצר תהליך שקוף, נעים ופותח דלתות
										</Typography>
									</Grid>
									<Grid
										item
										style={{
											marginTop: "1em",
											alignSelf: "center",
										}}
									>
										<Button
											variant="contained"
											color="secondary"
											onClick={() =>
												setShowCompanyContact(
													!showCompanyContact
												)
											}
										>
											<Typography
												variant="subtitle1"
												className={
													classes.clickHereText
												}
											>
												לחץ כאן {"\xa0"}
											</Typography>
											<Typography
												variant="subtitle1"
												className={classes.creamText}
											>
												ליצירת קשר
											</Typography>
										</Button>
									</Grid>
								</Grid>
							</Grid>
							{matchesMD && (
								<Grid
									item
									style={{
										maxWidth: matchesXS ? "100%" : "30em",
										alignSelf: "center",
										marginBottom: "2em",
									}}
								>
									{showCompanyContact && (
										<ContactComponent
											header="יצירת/שדרוג תהליך גיוס"
											marginTop="2em"
											defaultReason="מעסיק"
											reduceHeaderSize="true"
										/>
									)}
								</Grid>
							)}
							<Grid item>
								{matchesMD && (
									<img
										src={recruiters}
										alt="recuiters"
										className={classes.recruitersImage}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
					{!matchesMD && (
						<Grid
							item
							style={{
								maxWidth: "40em",
								alignSelf: "center",
								marginBottom: "4em",
							}}
						>
							{showCompanyContact && (
								<ContactComponent
									header="יצירת/שדרוג תהליך גיוס"
									marginTop="2em"
									defaultReason="מעסיק"
								/>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

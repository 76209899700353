// takes a list of items and returns a list of lists of items to display.
export function getItemsSets(
	items,
	xsSize,
	smSize,
	mdSize,
	lgSize,
	matchesXS,
	matchesSM,
	matchesMD
) {
	let itemsToReturn = [];
	let setLength = matchesXS
		? xsSize
		: matchesSM
		? smSize
		: matchesMD
		? mdSize
		: lgSize;
	let itemIndex = 1;
	let itemsList = [];
	let finishIterating = false;
	items.map((item) => {
		if (itemIndex < setLength) {
			itemsList.push(item);
			itemIndex++;
			finishIterating = false;
		} else {
			itemsList.push(item);
			itemsToReturn.push(itemsList);
			itemIndex = 1;
			itemsList = [];
			finishIterating = true;
		}
		return null;
	});
	if (!finishIterating) {
		itemsToReturn.push(itemsList);
	}
	return itemsToReturn;
}

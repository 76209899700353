import React, { useEffect, useState, useMemo } from "react";
import {
	useScrollTrigger,
	AppBar,
	makeStyles,
	Tab,
	Tabs,
	SwipeableDrawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import SocialMediaIcons from "../ui/SocialMediaIcons";
import logo from "../../assets/phr.svg";

function ElevationScroll(props) {
	const { children, window } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

const useStyles = makeStyles((theme) => ({
	appbarContainer: {
		backgroundColor: theme.palette.common.mainBg,
		opacity: "100%",
		height: "4em",
		boxShadow: theme.shadows[0],
		borderBottom: "3px solid" + theme.palette.common.orangeColor,
		zIndex: theme.zIndex.modal + 1,
		marginBottom: "4em",
	},
	tabsContainer: {
		"& .MuiTab-root": {
			fontFamily: "Rubik",
			fontSize: "2rem",
			fontWeight: "800",
		},
		"& .MuiTab-wrapper": {
			height: "1.7em",
		},
		"& .MuiTabs-indicator": {
			backgroundColor: "transparent",
		},
	},
	tabStyle: {
		color: theme.palette.common.blueColor,
		bottomBorder: "0",
	},
	selectedTabStyle: {
		color: theme.palette.common.darkerBlueColor,
		bottomBorder: "0",
		background: "linear-gradient(to top, #FFA000, #fbe28a)",
		backgroundColor: theme.palette.common.tabBackground,
	},
	logo: {
		position: "fixed",
		zIndex: theme.zIndex.modal + 1,
		top: "0.2em",
		height: "3.5em",
		maxWidth: "244px",
		right: "auto",
		left: "4.5em",
		[theme.breakpoints.down("lg")]: {
			left: "0.5em",
		},
	},
	toolbarMargin: {
		marginBottom: "4em",
	},
	toolbarPadding: {
		paddingBottom: "4em",
	},
	selectedDrawerItem: {
		fontFamily: "Rubik",
		fontSize: "1.5rem",
		fontWeight: "800",
	},
	drawerItem: {
		fontFamily: "Rubik",
		fontSize: "1.5rem",
		fontWeight: "800",
		opacity: "0.7",
	},
	drawerPaper: {
		color: theme.palette.common.blueColor,
		backgroundColor: theme.palette.common.mainBg,
		"& .MuiListItem-root.Mui-selected": {
			color: theme.palette.common.darkerBlueColor,
			background: "linear-gradient(to bottom, #FFA000, #fbe28a)",
		},
	},
	drawerContainer: {
		"& .MuiListItem-root": {
			textAlign: "right",
		},
		"& .MuiButtonBase-root": {
			justifyContent: "right",
		},
	},
	drawerMenuContainer: {
		"&:hover": {
			backgroundColor: "transparent",
		},
		marginLeft: "auto",
		marginRight: "0.5rem",
	},
	drawerMenuIcon: {
		height: "40px",
		width: "40px",
		color: theme.palette.common.blueColor,
	},
}));

export default function Header(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const [openDrawer, setOpenDrawer] = useState(false);

	const routes = useMemo(() => [
		{
			name: "עמוד ראשי",
			link: "/",
			activeTab: 0,
		},
		{
			name: "אודותינו",
			link: "/about",
			activeTab: 1,
		},
		{
			name: "תחומי פעילות",
			link: "/activity-fields",
			activeTab: 2,
		},
		{
			name: "צור קשר",
			link: "/contact",
			activeTab: 3,
			isLast: true,
		},
	]);

	const drawerTabs = (
		<React.Fragment>
			<div className={classes.toolbarMargin} />
			<List disablePadding>
				{routes.map((route, i) => (
					<ListItem
						key={i}
						component={Link}
						to={route.link}
						disableRipple
						divider
						button
						onClick={() => {
							setOpenDrawer(false);
							handleChange(route.value);
						}}
						selected={value === route.activeTab}
						key={`${route}${route.activeTab}`}
					>
						<ListItemText
							disableTypography
							className={
								value === route.activeTab
									? classes.selectedDrawerItem
									: classes.drawerItem
							}
						>
							{route.name}
						</ListItemText>
					</ListItem>
				))}
			</List>
		</React.Fragment>
	);

	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

	const tabs = (
		<Tabs
			value={value}
			onChange={handleChange}
			centered
			className={classes.tabsContainer}
		>
			{routes.map((route, i) => (
				<Tab
					key={i}
					label={route.name}
					className={
						value === route.activeTab
							? classes.selectedTabStyle
							: classes.tabStyle
					}
					style={{
						marginLeft:
							!route.isLast && !matchesMD ? "2em" : undefined,
					}}
					component={Link}
					to={route.link}
					disableRipple
				/>
			))}
		</Tabs>
	);

	const drawer = (
		<React.Fragment>
			<SwipeableDrawer
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				open={openDrawer}
				anchor="right"
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
				className={classes.drawerContainer}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				{drawerTabs}
			</SwipeableDrawer>
			<IconButton className={classes.drawerMenuContainer}>
				<MenuIcon
					className={classes.drawerMenuIcon}
					onClick={() => setOpenDrawer(!openDrawer)}
				/>
			</IconButton>
		</React.Fragment>
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		[...routes].forEach((route) => {
			if (window.location.pathname === route.link) {
				if (value !== route.activeTab) {
					setValue(route.activeTab);
				}
			}
		});
	}, [props, value, routes]);

	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<ElevationScroll>
				<React.Fragment>
					<AppBar
						position="fixed"
						className={classes.appbarContainer}
					>
						{matchesSM ? drawer : tabs}
					</AppBar>
					<a
						href="/"
						style={{
							textDecoration: "none",
						}}
					>
						<img
							src={logo}
							alt="PHR company logo"
							className={classes.logo}
						/>
					</a>
					<SocialMediaIcons />
				</React.Fragment>
			</ElevationScroll>
			<div className={classes.toolbarPadding} />
		</>
	);
}

import React from "react";
import {
	makeStyles,
	Grid,
	Typography,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import ContactComponent from "../ui/ContactComponent";

import bg1 from "../../assets/bg1.jpg";
import phrHR from "../../assets/PHR-hr.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.common.mainBg,
	},
	rightContainer: {
		backgroundImage: `url(${bg1})`,
		backgroundPositionX: "40%",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		maxHeight: "70em",
	},
	title: {
		...theme.typography.pageHeader,
	},
	leftContainer: {
		marginTop: "2em",
		marginBottom: "3em",
	},
	textContainer: {
		marginRight: "2em",
		maxWidth: "60em",
		[theme.breakpoints.up("md")]: {
			maxWidth: "55em",
		},
		[theme.breakpoints.down("md")]: {
			maxWidth: "41em",
		},
		[theme.breakpoints.down("sm")]: {
			maxWidth: "35em",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "21em",
		},
	},
	paragraphHeader: {
		...theme.typography.subtitle1Header,
		marginTop: "2em",
	},
	paragraph: {
		marginTop: "2em",
		lineHeight: "1.5",
	},
	juditDetailsHeader: {
		color: theme.palette.common.blueColor,
		marginTop: "2em",
		lineHeight: "1.5",
		fontSize: "1.5rem",
		fontWeight: "600",
	},
	juditDetailsBody: {
		color: theme.palette.common.blueColor,
		lineHeight: "1.5",
		fontSize: "1.2rem",
		fontWeight: "500",
	},
}));
export default function AbousUs(props) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Grid container className={classes.root}>
			<Grid
				item
				xs={matchesSM ? 0 : 3}
				className={classes.rightContainer}
			/>
			<Grid item xs={matchesSM ? 12 : 9}>
				<Grid
					container
					direction="column"
					className={classes.leftContainer}
				>
					<Grid
						item
						style={{
							position: "absolute",
							top: matchesSM ? "12em" : "5em",
							transform: "scaleX(-1)",
							width: matchesSM ? "90%" : "70%",
							opacity: "7%",
							height: matchesXS
								? "25em"
								: matchesSM
								? "35em"
								: matchesMD
								? "45em"
								: "50em",
							backgroundImage: `url(${phrHR})`,
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
						}}
					></Grid>
					<Grid item>
						<Grid
							container
							direction="column"
							className={classes.textContainer}
						>
							<Grid item>
								<Grid container>
									<Grid item>
										<Typography className={classes.title}>
											פרופיל החברה
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item container direction="column">
								<Grid item>
									<Typography
										variant="subtitle1"
										className={classes.paragraph}
									>
										ב P.H.R מיישמים מידי יום את החזון שלשמה
										הוקמה החברה: <br />
										למצוא התאמה מקסימלית בין צורכי{" "}
										<b>מחפש העבודה</b> ורצונותיו לבין{" "}
										<b>צורכי המעסיק</b> והתפקיד, <br /> תוך
										שימת דגש גם על התאמה מקצועית וגם על
										כימיה בין-אישית
										<br />
										וכן שמירה על תהליך שקוף, נעים ופותח
										דלתות.
									</Typography>
									<Typography
										variant="subtitle1"
										className={classes.paragraph}
									>
										התוצר של תהליך מורכב זה מוכיח את עצמו
										בשיעורי התאמה מרשימים הבאים לידי ביטוי
										במעסיקים מרוצים <br /> ובעובדים שמוצאים
										בית תעסוקתי לשנים רבות.
										<br />
										אנו מומחים ביצירת פתרונות <b>
											השמה
										</b>{" "}
										מידיים למגזרים:{" "}
										<b>
											הייטק, טכנולוגיה, תעשייה, פיננסים,
											ארגונים עסקיים בארץ ובחו"ל
										</b>{" "}
										- עבור מגוון תפקידים נרחב, בדרגים בכיר,
										בינוני, ותפקידי הנדסה
									</Typography>
									<Typography
										variant="subtitle1"
										className={classes.paragraph}
									>
										ב P.H.R סיגלנו לעצמינו{" "}
										<b>שיטת גיוס ייחודית</b> הפונה לא רק
										לשוק מחפשי העבודה
										<br /> אלא גם למועמדים מועסקים הנמצאים
										מחוץ ל"<b>שוק מחפשי העבודה</b>" מאגר
										המועמדים שלנו איכותי ומגיע ממקורות
										ראשוניים ובלעדיים.
									</Typography>
									<Typography
										variant="subtitle1"
										className={classes.juditDetailsHeader}
									>
										מייסדת ומנהלת החברה, יהודית ציטר
									</Typography>
									<Typography
										variant="subtitle1"
										className={classes.juditDetailsBody}
									>
										מומחית באיתור והשמת בכירים
										<br /> בוגרת MA במנהל עסקים, BA במדעי
										ההתנהגות
										<br /> מוסמכת איק"א – אימון אישי לבניית
										קריירה
										<br /> בעלת ניסיון של 25 שנים בתפקידי
										סמנכ"ל משאבי אנוש בארגונים מובילים
									</Typography>
								</Grid>
							</Grid>
							<Grid item container dierction="column">
								<Grid item>
									<Typography
										className={classes.paragraphHeader}
									>
										הזדמנויות תעסוקה
									</Typography>
									<Typography variant="subtitle1">
										באתר P.H.R ובאתרי הפרסום השונים נפתחות
										מידי יום <b>משרות חדשות</b>
										<br /> להן ניתן לפנות בקלות על ידי הרשמה
										וטעינת <b>קורות חיים</b> חד פעמית
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container justify="center">
							<Grid
								item
								style={{
									marginTop: "3em",
									maxWidth: "100%",
								}}
							>
								<ContactComponent header="צרו קשר" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

import { createMuiTheme } from "@material-ui/core/styles";

const blueColor = "#01498e";
const darkerBlueColor = "#386ed4";
const creamColor = "#FFFCF2";
const tabBackground = "#fbe28a";
const orangeColor = "#FFA000";
const hoverOrange = "#ffc462";
const mainBg = "#FFFDFA";
const mainGrey = "#455A64";
const componentsBackground = "#edecea";
const errorColor = "#c62828";

export default createMuiTheme({
	palette: {
		common: {
			blueColor: blueColor,
			darkerBlueColor: darkerBlueColor,
			creamColor: creamColor,
			errorColor: errorColor,
			tabBackground: tabBackground,
			orangeColor: orangeColor,
			hoverOrange: hoverOrange,
			mainBg: mainBg,
			mainGrey: mainGrey,
			componentsBackground: componentsBackground,
		},
		primary: {
			main: orangeColor,
		},
		secondary: {
			main: blueColor,
		},
	},
	typography: {
		regularOrange: {
			fontFamily: "Alef",
			color: orangeColor,
			fontSize: "1.2rem",
			fontWeight: "400",
		},
		largeOrange: {
			fontFamily: "Rubik",
			color: orangeColor,
			fontSize: "2rem",
			fontWeight: "700",
		},
		regularWhite: {
			fontFamily: "Rubik",
			color: "white",
			fontSize: "1.2rem",
			fontWeight: "400",
		},
		largeWhite: {
			fontFamily: "Rubik",
			color: "white",
			fontSize: "2rem",
			fontWeight: "700",
		},
		sendCvBtn: {
			fontFamily: "Rubik",
			fontSize: "2rem",
			color: orangeColor,
		},
		sendContactBtn: {
			fontFamily: "Rubik",
			fontSize: "1rem",
			color: "white",
			backgroundColor: orangeColor,
			"&:hover": {
				backgroundColor: hoverOrange,
			},
		},
		footerText: {
			fontFamily: "Rubik",
			fontSize: "1rem",
			fontWeight: 400,
			color: creamColor,
		},
		footerTextHeader: {
			fontFamily: "Rubik",
			fontSize: "2rem",
			fontWeight: 400,
			color: orangeColor,
		},
		pageSeparatorHeader: {
			fontFamily: "Rubik",
			fontSize: "2.5rem",
			fontWeight: 400,
		},
		pageHeader: {
			fontFamily: "Rubik",
			fontSize: "3rem",
			fontWeight: 700,
			color: orangeColor,
		},
		subtitle1: {
			fontFamily: "Rubik",
			fontWeight: "400",
			lineHeight: "1.4",
			color: mainGrey,
		},
		subtitle1Header: {
			fontFamily: "Rubik",
			fontSize: "1.7rem",
			color: mainGrey,
		},
		subtitle2: {
			fontFamily: "Rubik",
			fontSize: "0.8rem",
			color: mainGrey,
		},
	},
	overrides: {
		MuiFormHelperText: {
			root: {
				direction: "rtl",
				textAlign: "right",
				backgroundColor: mainGrey,
				marginTop: "0",
			},
		},
	},
});

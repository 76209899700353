import React, { useState } from "react";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Grid,
	Typography,
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	useMediaQuery,
	CircularProgress,
} from "@material-ui/core";
import airplain from "../../assets/send.svg";
import UploadButton from "./UploadButton";
import SnackbarComponent from "../ui/SnackbarComponent";
import {
	validateEmailInput,
	validatePhoneInput,
	validateSubmit,
} from "../services/formInputService";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.common.componentsBackground,
		"& .MuiFilledInput-input": {
			paddingTop: "27px",
			paddingBottom: "3px",
		},
		"& .MuiFilledInput-root": {
			borderRadius: "0",
			backgroundColor: "white",
			[theme.breakpoints.down("xs")]: {
				width: "20em",
			},
		},
		"& .MuiInputLabel-formControl": {
			left: "auto",
			right: "1em",
			width: "15em",
			fontFamily: "Alef",
		},
		"& .MuiFilledInput-underline:before": {
			borderBottom: "0px",
		},
		"& .MuiInputLabel-shrink": {
			transformOrigin: "top right",
			marginTop: "-0.5em",
		},
		"& .MuiFormHelperText-root": {
			backgroundColor: "transparent",
		},
	},
	messageTextBox: {
		"& .MuiFilledInput-input": {
			paddingTop: "0",
		},
		"& .MuiFilledInput-root": {
			borderRadius: "0",
			backgroundColor: "white",
			width: "20em",
			minHeight: "5em",
		},
		"& .MuiInputLabel-formControl": {
			left: "auto",
			right: "1em",
			fontFamily: "Alef",
		},
		"& .MuiFilledInput-underline:before": {
			borderBottom: "0px",
		},
		"& .MuiInputLabel-shrink": {
			transformOrigin: "top right",
			marginTop: "-0.5em",
		},
		"& .MuiFormHelperText-root": {
			backgroundColor: "transparent",
		},
	},
	header: {
		...theme.typography.largeOrange,
		color: theme.palette.common.mainBg,
		paddingBottom: "0.2em",
	},
	sendContactBtn: {
		...theme.typography.sendContactBtn,
	},
	selector: {
		"& .MuiFormControl-root": {
			marginRight: "0.5em",
			width: "7em",
		},
	},
	headerShapeAndText: {
		backgroundColor: theme.palette.common.orangeColor,
		borderBottomLeftRadius: "100%",
		marginBottom: "1em",
		paddingRight: "1em",
	},
}));

const sendBtnContents = (
	<React.Fragment>
		<Typography
			variant="subtitle1"
			style={{ color: "white", fontSize: "1.5rem", fontFamily: "rubik" }}
		>
			שלח
		</Typography>
		<img
			src={airplain}
			alt="paper airplain"
			style={{ marginRight: "1em" }}
		/>
	</React.Fragment>
);

export default function ContactComponent(props) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

	const [nameProps, setNameProps] = useState({
		name: "",
		surname: "",
	});
	const [emailProps, setEmailProps] = useState({
		email: "",
		helper: "",
	});
	const [phoneProps, setPhoneProps] = useState({
		phone: "",
		helper: "",
	});
	const [message, setMessage] = useState("");
	const [alert, setAlert] = useState({
		open: false,
		message: "",
		backgroundColor: "",
	});
	const [file, setFile] = useState(null);
	const [filename, setFilename] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isContactReason, setContactReason] = useState(
		props.defaultReason ? props.defaultReason : ""
	);
	const [
		isContactReasonSelectorOpen,
		setContactReasonSelectorOpen,
	] = useState(false);

	function verifyShowSendCvBtn() {
		return isContactReason !== "מעסיק";
	}

	function onChange(event) {
		switch (event.target.id) {
			case "email":
				validateEmailInput(event, setEmailProps);
				break;
			case "phone":
				validatePhoneInput(event, setPhoneProps);
				break;
			default:
				break;
		}
	}

	const fieldsToValidate = [
		{
			id: "name",
			value: nameProps.name,
			isRequired: true,
		},
		{
			id: "surname",
			value: nameProps.surname,
			isRequired: true,
		},
		{
			id: "email",
			value: emailProps.email,
			isRequired: true,
			helper: emailProps.helper,
		},
		{
			id: "phone",
			value: phoneProps.phone,
			isRequired: true,
			helper: phoneProps.helper,
		},
		{
			id: "message",
			value: message,
			isRequired: false,
		},
		{
			id: "contactReason",
			value: isContactReason,
			isRequired: false,
		},
	];

	async function onSubmit(event) {
		event.preventDefault();

		if (!validateSubmit(fieldsToValidate, setAlert)) {
			return;
		}

		if (file !== null) {
			setLoading(true);
			const formData = new FormData();
			formData.append("file", file);
			await axios
				.post("https://phr.team:5000/upload", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.catch((error) => console.error(error));
		}

		const dataToSend = {
			name: nameProps.name,
			surname: nameProps.surname,
			email: emailProps.email,
			phone: phoneProps.phone,
			contactReason: isContactReason ? isContactReason : "לא הוזן",
			message: message ? message : "לא הוזן",
			filename: filename,
		};

		axios
			.post("https://phr.team:5000/api/sendMail", dataToSend)
			.then((res) => {
				setLoading(false);
				setNameProps({ name: "", surname: "" });
				setEmailProps({ email: "", helper: "" });
				setPhoneProps({ phone: "", helper: "" });
				setMessage("");
				setContactReason("");
				setFile(null);
				setFilename("");
				setAlert({
					open: true,
					message: "ההודעה נשלחה בהצלחה!",
					backgroundColor: "#66BB6A",
				});
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
				setAlert({
					open: true,
					message: "משהו לא תקין, אנא נסה לשלוח מחדש",
					backgroundColor: "#c62828",
				});
			});
	}

	return (
		<React.Fragment>
			<Grid
				item
				container
				className={classes.root}
				style={{
					marginTop: props.marginTop,
					marginBottom: props.marginBottom,
				}}
			>
				<Grid container direction="column">
					<Grid item className={classes.headerShapeAndText}>
						<Typography
							className={classes.header}
							style={{
								fontSize: props.reduceHeaderSize && "1.5rem",
							}}
						>
							{props.header}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={matchesXS ? "column" : "row"}
							alignItems={matchesXS ? "center" : undefined}
							justify="space-around"
							style={{
								marginBottom: matchesXS ? undefined : "1.5em",
							}}
						>
							<Grid
								item
								style={{
									marginBottom: matchesXS
										? "0.5em"
										: undefined,
								}}
							>
								<TextField
									fullWidth
									variant="filled"
									label="*שם פרטי"
									id="name"
									value={nameProps.name}
									onChange={(event) =>
										setNameProps({
											name: event.target.value,
											surname: nameProps.surname,
										})
									}
								/>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: matchesXS
										? "0.5em"
										: undefined,
								}}
							>
								<Grid item>
									<TextField
										fullWidth
										variant="filled"
										label="*שם משפחה"
										id="surname"
										value={nameProps.surname}
										onChange={(event) =>
											setNameProps({
												name: nameProps.name,
												surname: event.target.value,
											})
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={matchesXS ? "column" : "row"}
							alignItems={matchesXS ? "center" : undefined}
							justify="space-around"
							style={{
								marginBottom: matchesXS ? undefined : "1.5em",
							}}
						>
							<Grid
								item
								style={{
									marginBottom: matchesXS
										? "0.5em"
										: undefined,
								}}
							>
								<TextField
									fullWidth
									variant="filled"
									error={phoneProps.helper.length !== 0}
									helperText={phoneProps.helper}
									label="*טלפון"
									id="phone"
									value={phoneProps.phone}
									onChange={(event) => onChange(event)}
								/>
							</Grid>
							<Grid
								item
								style={{
									marginBottom: matchesXS
										? "0.5em"
										: undefined,
								}}
							>
								<TextField
									fullWidth
									variant="filled"
									error={emailProps.helper.length !== 0}
									helperText={emailProps.helper}
									label="*כתובת אימייל"
									id="email"
									value={emailProps.email}
									onChange={(event) => onChange(event)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={matchesXS ? "column" : "row"}
							alignItems={matchesXS ? "center" : undefined}
							justify="space-around"
							style={{
								marginBottom: matchesXS ? undefined : "1.5em",
							}}
						>
							<Grid
								item
								style={{
									marginBottom: matchesXS
										? "0.5em"
										: undefined,
								}}
							>
								<TextField
									className={classes.messageTextBox}
									fullWidth
									variant="filled"
									multiline
									rowsMax={4}
									label="הכנס טקסט"
									id="message"
									value={message}
									onChange={(event) =>
										setMessage(event.target.value)
									}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid
							container
							justify="center"
							alignItems="center"
							style={{ marginBottom: "1em" }}
						>
							<Grid item>
								<Typography
									variant="subtitle1"
									style={{ direction: "ltr" }}
								>
									:סיבת הפניה
								</Typography>
							</Grid>
							<Grid item>
								<Grid
									container
									alignItems="center"
									className={classes.selector}
								>
									<Grid item>
										<FormControl>
											<Select
												open={
													isContactReasonSelectorOpen
												}
												onClose={() =>
													setContactReasonSelectorOpen(
														false
													)
												}
												onOpen={() =>
													setContactReasonSelectorOpen(
														true
													)
												}
												value={isContactReason}
												onChange={(event) =>
													setContactReason(
														event.target.value
													)
												}
											>
												<MenuItem value={"חיפוש משרה"}>
													חיפוש משרה
												</MenuItem>
												<MenuItem value={"ייעוץ"}>
													ייעוץ
												</MenuItem>
												<MenuItem value={"מעסיק"}>
													מעסיק
												</MenuItem>
												<MenuItem value={"אחר"}>
													אחר
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction="column" alignItems="center">
							{verifyShowSendCvBtn() && (
								<>
									<Grid
										item
										style={{ marginBottom: "0.5em" }}
									>
										<UploadButton
											file={file}
											setFile={setFile}
											filename={filename}
											setFilename={setFilename}
											setAlert={setAlert}
										/>
									</Grid>
									<Grid
										item
										style={{ marginBottom: "0.5em" }}
									>
										<Grid container>
											<Grid
												item
												style={{
													marginLeft: "0.5em",
												}}
											>
												<Typography variant="subtitle2">
													סוגי קבצים אפשריים:
												</Typography>
											</Grid>
											<Grid item>
												<Typography
													variant="subtitle2"
													style={{
														direction: "ltr",
													}}
												>
													.doc/.docx/.pdf
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</>
							)}
							<Grid item style={{ marginBottom: "1em" }}>
								{!isLoading ? (
									<form onSubmit={onSubmit}>
										<Button
											variant="outlined"
											type="submit"
											className={classes.sendContactBtn}
										>
											{sendBtnContents}
										</Button>
									</form>
								) : (
									<CircularProgress color="secondary" />
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography
						variant="subtitle2"
						style={{
							marginRight: "3em",
							marginBottom: "1em",
							color: "red",
						}}
					>
						* שדה חובה
					</Typography>
				</Grid>
			</Grid>
			<SnackbarComponent
				open={alert.open}
				message={alert.message}
				backgroundColor={alert.backgroundColor}
				setAlert={setAlert}
			/>
		</React.Fragment>
	);
}

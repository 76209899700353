import React from "react";
import Carousel from "react-material-ui-carousel";
import {
	makeStyles,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

import bgFindJobsFields from "../../assets/bgFindJobsFields.jpg";
import { getItemsSets } from "../services/displayItemsService";

const blue = "#3B4F8C";
const darkBrown = "#455A64";
const orange = "#FFA000";
const white = "#FFFFFF";

const useStyles = makeStyles((theme) => ({
	papersContainer: {
		paddingTop: "5em",
		paddingBottom: "5em",
		backgroundImage: `url(${bgFindJobsFields})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "70%",
		backgroundSize: "cover",
	},
	blockHeader: {
		...theme.typography.largeWhite,
		fontWeight: "600",
		lineHeight: "1em",
	},
	blockFieldsContainer: {
		width: "19.5em",
		height: "14em",
	},
	blockInnerContainer: {
		paddingTop: "0.5em",
		paddingRight: "0.5em",
	},
	field: {
		"&:hover": {
			color: theme.palette.common.errorColor,
		},
	},
	Carousel: {
		"& .MuiIconButton-root": {
			borderRadius: "0%",
			backgroundColor: "white",
			color: "black",
		},
	},
	liSquare: {
		listStyle: "square",
		fontSize: "1.3rem",
	},
}));

const blocks = [
	{
		color: darkBrown,
		textColor: white,
		headline: "שירות ואדמיניסטרציה",
		isSmallerBg: true,
		jobsBlocks: [
			{
				jobFields: [
					{
						name: 'עוזרת אישית מנכ"ל',
					},
					{
						name: 'עוזרת אישית סמנכ"ל',
					},
					{
						name: "מנהל מוקד",
					},
					{
						name: "מנהל מערך שירות",
					},
					{
						name: "מנהל אדימיסטרטיבי",
					},
					{
						name: "מזכירה אנגלית",
					},
					{
						name: "רכז שירות",
					},
					{
						name: "מנהל קשרי לקוחות",
					},
				],
			},
		],
	},
	{
		color: orange,
		textColor: blue,
		headline: "שווק ומכירות",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "מנהל מכירות ארצי",
					},
					{
						name: "מנהל מכירות איזורי",
					},
					{
						name: "מנהל עסקי",
					},
					{
						name: "אחראי גיוס משאבים",
					},
					{
						name: "מנהל מכירות",
					},
					{
						name: "אחראי תקשורת שווקית",
					},
					{
						name: "סופרוויזר",
					},
					{
						name: "מנהל מותג",
					},
				],
			},
			{
				jobFields: [
					{
						name: "מנהל סחר",
					},
				],
			},
		],
	},
	{
		color: blue,
		textColor: white,
		headline: "הנדסה/טכני/ביוטק",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "ראש תחום חשמל",
					},
					{
						name: "מהנדס מפעל",
					},
					{
						name: "טכנולוג איכות מזון",
					},
					{
						name: "טכנולוג איכות מתכת",
					},
					{
						name: "טכנולוג אריזות",
					},
					{
						name: "מהנדס מערכות מיים",
					},
					{
						name: "מנהל פרויקט חשמל",
					},
					{
						name: "מנהל אחזקה מפעלי",
					},
				],
			},
			{
				jobFields: [
					{
						name: "ראש תחום מיים וגזים",
					},
					{
						name: "מנהל פרוייקט בינוי",
					},
					{
						name: "מנהל מעבדה",
					},
					{
						name: "מנהל מחלקת הרכבות",
					},
					{
						name: "מנהל אחזקה",
					},
					{
						name: "מהנדס פיתוח מכונות",
					},
					{
						name: "PMO",
					},
					{
						name: "מנהל פרויקט אנרגיה",
					},
				],
			},
			{
				jobFields: [
					{
						name: "מנהל פרויקט תשתיות",
					},
					{
						name: "מנהל פרויקט הקמה",
					},
					{
						name: "מתכנן לוחות חשמל",
					},
					{
						name: "מהנדס מפעל",
					},
					{
						name: "בקר איכות",
					},
					{
						name: "מנהל תחום מזוג אויר",
					},
					{
						name: "מהנדס מכונות בכיר",
					},
					{
						name: "מנהל עבודה בניין",
					},
				],
			},
		],
	},
	{
		color: orange,
		textColor: blue,
		headline: "רכש ולוגיסטיקה",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "אנליסט",
					},
					{
						name: "מנהל לוגיסטיקה",
					},
					{
						name: "מנהל מרכז לוגיסטי",
					},
					{
						name: 'מנהל תפ"י',
					},
					{
						name: "מנהל הפצה",
					},
					{
						name: "מנהל רכש ומכרזים",
					},
					{
						name: "קניין טכני",
					},
					{
						name: "קניין אריזות",
					},
				],
			},
			{
				jobFields: [
					{
						name: "פלנר רכש",
					},
					{
						name: "פלנר ייצור",
					},
					{
						name: 'מנהל או"ש',
					},
					{
						name: "רכז יבוא",
					},
					{
						name: "מתכנן ביקושים ואספקות",
					},
				],
			},
		],
	},
	{
		color: blue,
		textColor: white,
		headline: "פיננסים",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "CFO",
					},
					{
						name: "רואה חשבון",
					},
					{
						name: "כלכלן",
					},
					{
						name: "בקר תקציב",
					},
					{
						name: "עוזר חשב",
					},
					{
						name: "מנהל חשבונות",
					},
					{
						name: "מנהל מימון",
					},
					{
						name: "מימון ואנליזה",
					},
				],
			},
			{
				jobFields: [
					{
						name: "חשב שכר",
					},
					{
						name: "אנליסט",
					},
					{
						name: "בקרת מסמכים",
					},
					{
						name: "מבקר פנים",
					},
				],
			},
		],
	},
	{
		color: darkBrown,
		textColor: white,
		headline: "IT/דיגיטל",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "מטמיע מערכת",
					},
					{
						name: "cyber security expert",
					},
					{
						name: "מנהל תוכן",
					},
					{
						name: "מיישם פריוריט",
					},
					{
						name: "מטמיע SAP",
					},
					{
						name: "מנהל IT",
					},
					{
						name: "תומך טכני",
					},
					{
						name: "אחראי תקשורת ומערכות מידע",
					},
				],
			},
			{
				jobFields: [
					{
						name: "מהנדס תוכנה",
					},
					{
						name: "בודק תוכנה",
					},
					{
						name: "מפתח בדיקות חומרה",
					},
					{
						name: "מנהל מוצר לוחמת סייבר",
					},
					{
						name: "מנהל מערכות מידע",
					},
					{
						name: "PMO",
					},
					{
						name: "מאפיין טכני",
					},
					{
						name: "מנהל פרוייקט טכנולוגי",
					},
				],
			},
			{
				jobFields: [
					{
						name: "מהנדס תהליך",
					},
					{
						name: "מנהל תחום טכנולוגיות",
					},
				],
			},
		],
	},
	{
		color: blue,
		textColor: white,
		headline: "בכירים",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "מנהל מדינה",
					},
					{
						name: "מנהל יחדה עסקית",
					},
					{
						name: 'משנה למנכ"ל',
					},
					{
						name: "מנהל פרוייקט הגנה ימית",
					},
					{
						name: 'סמנכ"ל תפעול',
					},
					{
						name: "מהנדס תפעול",
					},
					{
						name: 'סמנכ"ל בטיחות',
					},
					{
						name: 'סמנכ"ל אחריות תאגידית',
					},
					{
						name: 'סמנכ"ל משאבי אנוש',
					},
				],
			},
		],
	},
	{
		color: darkBrown,
		textColor: white,
		headline: "משרות נוספות",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "מנהלת גיוס",
					},
					{
						name: "מנהל הדרכה",
					},
					{
						name: "מנהל משאבי אנוש",
					},
					{
						name: "בקר איכות",
					},
					{
						name: "מנהל מחלקת רכבים",
					},
					{
						name: "מנהל משק ותחזוקת מבנים",
					},
					{
						name: "מנהל בטיחות מוצר",
					},
					{
						name: 'מנהל או"ש',
					},
				],
			},
			{
				jobFields: [
					{
						name: "רכז גיוס",
					},
					{
						name: "מנהל נכסים",
					},
					{
						name: "רכז אחריות תאגידים",
					},
				],
			},
		],
	},
	{
		color: orange,
		textColor: blue,
		headline: "רילוקיישן",
		jobsBlocks: [
			{
				jobFields: [
					{
						name: "מנהל שווק בית חולים",
					},
					{
						name: "CFO",
					},
					{
						name: "מנהל מדינה",
					},
					{
						name: "מנהל יחידה עסקית",
					},
					{
						name: "מנהל פרוייקט הגנה ימית",
					},
					{
						name: "מנהל פרוייקט טכנולוגי",
					},
					{
						name: "בקר תקציב",
					},
					{
						name: "מנהל מרכז לוגיסטי",
					},
				],
			},
		],
	},
];

export default function FindJobField(props) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

	const jobBlockSets = getItemsSets(
		blocks,
		1,
		1,
		3,
		3,
		matchesXS,
		matchesSM,
		matchesMD
	);

	const carousel = (block) => (
		<Carousel
			autoPlay={false}
			animation="slide"
			indicators={false}
			navButtonsAlwaysVisible
			className={classes.Carousel}
			isNew={true}
			isNextSlideAvailable={block.jobsBlocks.length > 1}
		>
			{block.jobsBlocks.map((jobsBlock, k) => (
				<Grid
					key={k}
					container
					direction="column"
					className={classes.blockFieldsContainer}
				>
					{blockFields(block, jobsBlock)}
				</Grid>
			))}
		</Carousel>
	);

	const blockFields = (block, jobsBlock) => (
		<ul
			style={{
				color: block.textColor,
				listStyle: "square",
				fontSize: "1.2rem",
			}}
		>
			{jobsBlock.jobFields.map((field, z) => (
				<Grid item key={z} className={classes.field}>
					<li>
						<Typography
							variant="subtitle1"
							style={{
								color: block.textColor,
							}}
						>
							{field.name}
						</Typography>
					</li>
				</Grid>
			))}
		</ul>
	);

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			className={classes.papersContainer}
		>
			<Grid item>
				{jobBlockSets.map((set, i) => (
					<Grid container key={i}>
						{set.map((block, j) => (
							<Grid item key={j}>
								<Grid
									container
									style={{
										backgroundColor: block.color,
									}}
								>
									<Grid item>
										<Grid
											container
											direction="column"
											className={
												classes.blockInnerContainer
											}
										>
											<Grid item>
												<Typography
													className={
														classes.blockHeader
													}
													style={{
														color:
															block.headerColor,
													}}
												>
													{block.headline}
												</Typography>
											</Grid>
											<Grid item>
												<Grid
													container
													direction="column"
												>
													<Grid item>
														{carousel(block)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
				))}
			</Grid>
		</Grid>
	);
}

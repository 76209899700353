import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";

export default function SnackbarComponent(props) {
	const theme = useTheme();

	return (
		<Snackbar
			open={props.open}
			message={props.message}
			ContentProps={{
				style: {
					anchorOriginTopCenter: {
						top: "4.5em",
					},
					root: {
						justifyContent: "center",
						right: "0",
					},
					backgroundColor: props.backgroundColor,
					boxShadow: theme.shadows[1],
					fontFamily: "Alef",
				},
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			onClose={() =>
				props.setAlert({
					open: false,
					message: "",
					backgroundColor: "",
				})
			}
			autoHideDuration={4000}
		/>
	);
}

import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./components/Theme";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import LandingPage from "./components/pages/LandingPage";
import AboutUs from "./components/pages/AboutUs";
import ActivityFields from "./components/pages/ActivityFields";
import Contact from "./components/pages/Contact";
import SendCv from "./components/pages/SendCv";

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Header />
				<Switch>
					<Route
						exact
						path="/"
						render={(props) => <LandingPage {...props} />}
					/>
					<Route
						exact
						path="/contact"
						render={(props) => (
							<Contact style={{ height: "20em" }} {...props} />
						)}
					/>
					<Route
						exact
						path="/about"
						render={(props) => <AboutUs {...props} />}
					/>
					<Route
						exact
						path="/activity-fields"
						render={(props) => <ActivityFields {...props} />}
					/>
					<Route
						exact
						path="/sendcv"
						render={(props) => <SendCv {...props} />}
					/>
				</Switch>
				<Footer />
			</BrowserRouter>
		</ThemeProvider>
	);
}

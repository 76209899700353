import React from "react";
import Carousel from "react-material-ui-carousel";
import {
	makeStyles,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

import { getItemsSets } from "../services/displayItemsService";
import nirlat from "../../assets/customers/nirlat.svg";
import nobelEnergy from "../../assets/customers/nobelEnergy.png";
import odis from "../../assets/customers/odis.png";
import schindler from "../../assets/customers/schindler.svg";
import shafir from "../../assets/customers/shafir.svg";
import shikunBinui from "../../assets/customers/shikunBinui.svg";
import silicom from "../../assets/customers/silicom.jpg";
import tabib from "../../assets/customers/tabib.png";
import tempo from "../../assets/customers/tempo.svg";
import weizmann from "../../assets/customers/weizmann.svg";
import dagatHaaretz from "../../assets/customers/dagatHaaretz.jpg";
import audioMedic from "../../assets/customers/audioMedic.png";
import chemovil from "../../assets/customers/chemovil.webp";
import mendelson from "../../assets/customers/mendelson.jpg";
import menorah from "../../assets/customers/menorah.jpg";
import mitrelli from "../../assets/customers/mitrelli.png";
import policity from "../../assets/customers/policity.png";
import shacham from "../../assets/customers/shacham.png";
import chemograf from "../../assets/customers/chemograf.svg";
import tmh from "../../assets/customers/tmh.svg";

const useStyles = makeStyles((theme) => ({
	pageSeparatorHeader: {
		...theme.typography.pageSeparatorHeader,
		color: theme.palette.common.blueColor,
		fontWeight: "700",
		borderBottom: "1px solid",
		borderColor: theme.palette.common.blueColor,
	},
}));

const carouselItems = [
	{
		logo: nirlat,
	},
	{
		logo: nobelEnergy,
	},
	{
		logo: schindler,
	},
	{
		logo: shafir,
	},
	{
		logo: shikunBinui,
	},
	{
		logo: silicom,
	},
	{
		logo: tabib,
	},
	{
		logo: tempo,
	},
	{
		logo: shacham,
	},
	{
		logo: weizmann,
	},
	{
		logo: dagatHaaretz,
	},
	{
		logo: audioMedic,
	},
	{
		logo: chemovil,
	},
	{
		logo: mitrelli,
	},
	{
		logo: policity,
	},
	{
		logo: mendelson,
	},
	{
		logo: menorah,
	},
	{
		logo: odis,
	},
	{
		logo: chemograf,
	},
	{
		logo: tmh,
	},
];

function Item(props) {
	return (
		<Grid container direction="column">
			<Grid
				item
				container
				justify="space-around"
				style={{ marginTop: "1em", marginBottom: "1em" }}
			>
				{props.set.map((item, index) => (
					<Grid item key={index}>
						<img
							src={item.logo}
							alt=""
							style={{ height: "3.5em" }}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
}

export default function CustomersSlider(props) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

	const itemsSets = getItemsSets(
		carouselItems,
		2,
		3,
		5,
		5,
		matchesXS,
		matchesSM,
		matchesMD
	);

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			style={{
				backgroundColor: theme.palette.common.basicBackground,
			}}
		>
			<Grid item>
				<Typography className={classes.pageSeparatorHeader}>
					מבין לקוחותינו
				</Typography>
			</Grid>
			<Grid
				item
				container
				direction="column"
				style={{ maxWidth: "70em" }}
			>
				<Carousel
					animation="slide"
					indicators={false}
					timeout={6000}
					interval={12000}
				>
					{itemsSets.map((set, i) => (
						<Item key={i} set={set} />
					))}
				</Carousel>
			</Grid>
		</Grid>
	);
}

import React from "react";
import {
	makeStyles,
	Grid,
	Typography,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import ContactComponent from "../ui/ContactComponent";

import bg1 from "../../assets/bg1.jpg";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.common.mainBg,
	},
	rightContainer: {
		backgroundImage: `url(${bg1})`,
		backgroundPositionX: "40%",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
	title: {
		...theme.typography.pageHeader,
	},
	mainContainer: {
		marginBottom: "3em",
	},
	leftContainer: {
		marginTop: "2em",
		marginBottom: "3em",
	},
	textContainer: {
		marginRight: "1em",
		maxWidth: "21em",
	},
	fieldContainer: {
		marginBottom: "0.5em",
	},
	fieldLeftSide: {
		width: "13em",
	},
	link: {
		lineHeight: "1.5em",
		"&:hover": {
			color: "#2196F3",
		},
	},
}));
export default function Contact(props) {
	const classes = useStyles();
	const theme = useTheme();

	const fields = [
		{
			name: "טלפון",
			values: [
				{
					value: "07-37309626",
					component: (
						<a
							style={{ textDecoration: "none", color: "inherit" }}
							href="tel:0737309626"
						>
							07-37309626
						</a>
					),
				},
				{
					value: "052-5454599",
					component: (
						<a
							style={{ textDecoration: "none", color: "inherit" }}
							href="tel:0525454599"
						>
							052-5454599
						</a>
					),
				},
			],
		},
		{
			name: "כתובת מייל",
			values: [
				{
					value: "jobs@phr.team",
					component: (
						<a
							style={{ textDecoration: "none", color: "inherit" }}
							href="mailto:jobs@phr.team"
						>
							jobs@phr.team
						</a>
					),
				},
				{
					value: "judit@phr.team",
					component: (
						<a
							style={{ textDecoration: "none", color: "inherit" }}
							href="mailto:judit@phr.team"
						>
							judit@phr.team
						</a>
					),
				},
			],
		},
		{
			name: "כתובות משרדינו",
			values: [
				{
					value: "רעננה, החרושת 18",
				},
				{
					value: "תל-אביב, יוסף קארו 15",
				},
			],
		},
		{
			name: "שימו וייז",
			values: [
				{
					value: "https://waze.com/ul/hsv8z1tpn9",
					component: (
						<a
							href="https://waze.com/ul/hsv8z1tpn9"
							style={{ textDecoration: "none", color: "inherit" }}
						>
							למשרד ברעננה לחץ כאן
						</a>
					),
				},
				{
					value: "https://waze.com/ul/hsv8wrwmck",
					component: (
						<a
							href="https://waze.com/ul/hsv8wrwmck"
							style={{ textDecoration: "none", color: "inherit" }}
						>
							למשרד בתל אביב לחץ כאן
						</a>
					),
				},
			],
		},
	];

	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Grid container className={classes.root}>
			<Grid
				item
				xs={matchesSM ? 0 : 3}
				className={classes.rightContainer}
			/>
			<Grid item xs={matchesSM ? 12 : 9}>
				<Grid
					container
					direction="column"
					className={classes.leftContainer}
				>
					<Grid item>
						<Grid
							container
							direction="column"
							className={classes.textContainer}
						>
							<Grid item>
								<Typography className={classes.title}>
									פרופיל החברה
								</Typography>
							</Grid>
							<Grid item>
								{fields.map((field, i) => (
									<Grid
										key={i}
										container
										justify="space-between"
										className={classes.fieldContainer}
									>
										<Grid item>
											<Typography variant="subtitle1">
												{field.name}
											</Typography>
										</Grid>
										<Grid item>
											<Grid
												container
												direction="column"
												className={
													classes.fieldLeftSide
												}
											>
												{field.values.map(
													(value, j) => (
														<Grid key={j} item>
															<Typography
																variant="subtitle1"
																className={
																	value.component
																		? classes.link
																		: undefined
																}
															>
																{value.component
																	? value.component
																	: value.value}
															</Typography>
														</Grid>
													)
												)}
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container justify="center">
							<Grid item>
								<ContactComponent
									isJobRequired={false}
									isCvRequired={false}
									isContactReasonRequired={true}
									header={
										matchesSM
											? "צרו קשר"
											: "צרו קשר עם מומחה השמה"
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: "center",
	},
	input: {
		display: "none",
	},
}));

function isFileExtensionValid(filename) {
	const validExtensions = ["pdf", "doc", "docx"];
	const currentExtension = filename.split(".")[1];
	return validExtensions.includes(currentExtension);
}

export default function UploadButton(props) {
	const classes = useStyles();
	const theme = useTheme();

	function onFileSelect(event) {
		const file = event.target.files[0];
		if (file) {
			if (isFileExtensionValid(file.name)) {
				props.setFile(file);
				props.setFilename(file.name);
			} else {
				props.setFile(null);
				props.setFilename("Choose File");
				props.setAlert({
					open: true,
					message: "סוג הקובץ שבחרת אינו נתמך",
					backgroundColor: theme.palette.common.errorColor,
				});
			}
		} else {
			props.setFile(null);
			props.setFilename("");
		}
	}

	return (
		<div className={classes.root}>
			<label htmlFor="contained-button-file">
				<input
					// accept=".pdf, .doc, .docx"
					className={classes.input}
					id="contained-button-file"
					type="file"
					onChange={onFileSelect}
				/>
				<Button
					variant="outlined"
					color="secondary"
					component="span"
					style={{
						color: theme.palette.common.darkerBlueColor,
						fontFamily: "Alef",
						fontSize: "1rem",
						fontWeight: "700",
						backgroundColor: "white",
						border: "1px solid",
					}}
					disableElevation
				>
					בחר קובץ קורות חיים
				</Button>
			</label>
			<Typography variant="subtitle2" style={{ color: "green" }}>
				{props.filename}
			</Typography>
		</div>
	);
}

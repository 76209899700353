export function validateEmailInput(event, setEmail) {
	let valid = true;
	setEmail({ email: event.target.value, helper: "" });
	valid = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
		event.target.value
	);
	if (event.target.value.length === 0) {
		valid = true;
	}
	if (!valid) {
		setEmail({
			email: event.target.value,
			helper: "כתובת האימייל שהוכנסה אינה תקינה",
		});
	} else {
		setEmail({ email: event.target.value, helper: "" });
	}
}

export function validatePhoneInput(event, setPhone) {
	let valid = true;
	setPhone({ phone: event.target.value, helper: "" });
	valid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
		event.target.value
	);
	if (event.target.value.length === 0) {
		valid = true;
	}
	if (!valid) {
		setPhone({
			phone: event.target.value,
			helper: "המספר טלפון שהוכנס אינו תקין",
		});
	} else {
		setPhone({ phone: event.target.value, helper: "" });
	}
}

export function validateSubmit(fieldsToValidate, setAlert) {
	let isValid = true;
	fieldsToValidate.forEach((field) => {
		if (field.isRequired && field.value.length === 0) {
			setAlert({
				open: true,
				message: "אחד משדות החובה נשאר ריק",
				backgroundColor: "#c62828",
			});
			isValid = false;
		} else if (field.helper && field.helper.length !== 0) {
			setAlert({
				open: true,
				message: "ישנה שגיאה באחד מהשדות",
				backgroundColor: "#c62828",
			});
			isValid = false;
		}
	});
	return isValid;
}

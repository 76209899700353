import React, { useState } from "react";
import axios from "axios";
import {
	Grid,
	makeStyles,
	useTheme,
	Typography,
	Button,
	TextField,
	useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomersSlider from "../ui/CustomersSlider";
import JobFields from "../ui/JobFields";
import SnackbarComponent from "../ui/SnackbarComponent";
import {
	validateEmailInput,
	validatePhoneInput,
	validateSubmit,
} from "../services/formInputService";

import bg1 from "../../assets/bg1.jpg";
import bgXS from "../../assets/bgXS.jpg";
import bgMD from "../../assets/bgMD.jpg";
import DraftsIcon from "@material-ui/icons/Drafts";
import logo from "../../assets/phr.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.common.mainBg,
	},
	landingPageImage: {
		height: "432px",
		backgroundImage: `url(${bg1})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPositionY: "45%",
		transform: "rotateY(180deg)",
		[theme.breakpoints.down("md")]: {
			height: "400px",
			backgroundImage: `url(${bgMD})`,
		},
		[theme.breakpoints.down("xs")]: {
			backgroundPositionY: "0%",
			height: "350px",
			backgroundImage: `url(${bgXS})`,
			transform: "rotateY(0deg)",
		},
	},
	cardHeader: {
		...theme.typography.largeOrange,
		fontSize: "3rem",
		[theme.breakpoints.down("md")]: {
			fontSize: "2.5rem",
		},
	},
	sentSuccessfully: {
		...theme.typography.largeWhite,
		textAlign: "center",
	},
	cardFormSubHeader: {
		...theme.typography.largeWhite,
		fontSize: "1.4rem",
		fontWeight: "400",
		lineHeight: "1",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("md")]: {
			fontSize: "1.5rem",
		},
	},
	cardFormSubHeaderBold: {
		...theme.typography.largeWhite,
		fontWeight: "400",
		lineHeight: "0.5",
		fontSize: "3rem",
		paddingBottom: "0.2em",
		[theme.breakpoints.down("md")]: {
			fontSize: "2.5rem",
		},
	},
	envelopeIcon: {
		marginRight: "0.5em",
		color: theme.palette.common.orangeColor,
		fontSize: "3rem",
		[theme.breakpoints.down("md")]: {
			fontSize: "2rem",
		},
	},
	sendBtnText: {
		fontFamily: "Rubik",
		fontWeight: "700",
		fontSize: "2rem",
		color: "white",
		[theme.breakpoints.down("md")]: {
			fontSize: "1.5rem",
		},
	},
	cardLeftContainer: {
		background: "linear-gradient(to top, #44a0ff, #0d345c)",
	},
	cardRightContainer: {
		background: "linear-gradient(to top, #44a0ff, #0d345c)",
	},
	employerSendForm: {
		"& .MuiFilledInput-input": {
			padding: "0.5em",
			paddingLeft: "2em",
			color: "white",
			fontFamily: "Alef",
			[theme.breakpoints.down("sm")]: {
				paddingLeft: "0em",
			},
		},
		"& .MuiInputLabel-formControl": {
			left: "auto",
			right: "1em",
			top: "-0.5em",
			fontFamily: "Alef",
		},
		"& .MuiInputLabel-shrink": {
			opacity: "0",
		},
		"& .MuiFormLabel-root": {
			color: theme.palette.common.creamColor,
		},
	},
	sendBtn: {
		boxShadow: theme.shadows[0],
		borderRadius: "0%",
		height: "6em",
		[theme.breakpoints.down("md")]: {
			height: "5em",
		},
	},
	sendCvBtn: {
		boxShadow: theme.shadows[0],
		borderRadius: "0%",
		backgroundColor: theme.palette.common.mainBg,
		height: "6em",
		width: "22em",
		[theme.breakpoints.down("md")]: {
			width: "20em",
			height: "5em",
		},
		[theme.breakpoints.down("sm")]: {
			height: "3em",
			width: "100%",
		},
	},
	sendCvBtnComponentContainer: {
		"& .MuiButton-contained:hover": {
			backgroundColor: theme.palette.common.mainBg,
			boxShadow: theme.shadows[0],
		},
	},
	employersFormSubmitBtn: {
		boxShadow: theme.shadows[0],
		color: theme.palette.common.mainBg,
		borderRadius: "0%",
		height: "6em",
		backgroundColor: theme.palette.common.orangeColor,
		width: "20em",
		[theme.breakpoints.down("md")]: {
			height: "5em",
		},
		[theme.breakpoints.down("sm")]: {
			height: "3em",
			width: "100%",
		},
		"& .MuiButton-contained:hover": {
			backgroundColor: theme.palette.common.orangeColor,
			boxShadow: theme.shadows[0],
		},
	},
	cardContent: {
		textAlign: "center",
		minHeight: "24em",
		paddingBottom: "1em",
		[theme.breakpoints.down("md")]: {
			minHeight: "21em",
		},
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "0em",
		},
		[theme.breakpoints.down("xs")]: {
			paddingBottom: "0em",
			minHeight: "20em",
		},
	},
	cardContentAfterSent: {
		textAlign: "center",
		maxHeight: "25em",
		paddingBottom: "2em",
	},
	logo: {
		position: "absolute",
		zIndex: theme.zIndex.modal,
		top: "11em",
		height: "11em",
		right: "auto",
		left: "10em",
		[theme.breakpoints.down("md")]: {
			height: "8em",
			left: "2em",
		},
	},
	cardsPage: {
		marginTop: "4em",
		marginBottom: "4em",
		[theme.breakpoints.down("md")]: {
			marginTop: "2em",
			marginBottom: "2em",
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: "0em",
			marginBottom: "0em",
		},
	},
}));

function checkError(item) {
	if (item.helper && item.helper.length !== 0) {
		return true;
	}
	return false;
}

export default function LandingPage() {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

	const [companyName, setCompanyName] = useState("");
	const [emailProps, setEmailProps] = useState({
		email: "",
		helper: "",
	});
	const [phoneProps, setPhoneProps] = useState({
		phone: "",
		helper: "",
	});
	const [isSent, setSent] = useState(false);
	const [alert, setAlert] = useState({
		open: false,
		message: "",
		backgroundColor: "",
	});

	function onChange(event) {
		switch (event.target.id) {
			case "companyName":
				setCompanyName(event.target.value);
				break;
			case "email":
				validateEmailInput(event, setEmailProps);
				break;
			case "phone":
				validatePhoneInput(event, setPhoneProps);
				break;
			default:
				break;
		}
	}

	const fieldsToValidate = [
		{
			id: "companyName",
			value: companyName,
			isRequired: true,
		},
		{
			id: "email",
			value: emailProps.email,
			helper: emailProps.helper,
			isRequired: true,
		},
		{
			id: "phone",
			value: phoneProps.phone,
			helper: phoneProps.helper,
			isRequired: true,
		},
	];

	function submitCompany(event) {
		event.preventDefault();

		if (!validateSubmit(fieldsToValidate, setAlert)) {
			return;
		}

		const dataToSend = {
			companyName,
			email: emailProps.email,
			phone: phoneProps.phone,
		};

		axios
			.post("https://phr.team:5000/api/registerCompany", dataToSend)
			.then((res) => {
				setSent(true);
				setCompanyName("");
				setEmailProps({ email: "", helper: "" });
				setPhoneProps({ phone: "", helper: "" });
			})
			.catch((error) => {
				setSent(false);
				setAlert({
					open: true,
					message: "משהו לא תקין, אנא נסה לשלוח מחדש",
					backgroundColor: theme.palette.common.errorColor,
				});
			});
	}

	const sendCvBtnComponent = (
		<Button
			component={Link}
			to="/sendcv"
			variant="contained"
			className={classes.sendCvBtn}
		>
			<Typography
				className={classes.sendBtnText}
				style={{
					color: theme.palette.common.orangeColor,
				}}
			>
				שלח לנו קו"ח
			</Typography>
			<DraftsIcon className={classes.envelopeIcon} />
		</Button>
	);

	const employeeSendBtn = (
		<Button
			variant="contained"
			type="submit"
			form="employersForm"
			className={matchesSM ? classes.sendCvBtn : classes.sendBtn}
			style={{
				backgroundColor: theme.palette.common.orangeColor,
				width: matchesSM ? undefined : "20em",
			}}
		>
			<Typography
				className={classes.sendBtnText}
				style={{
					color: theme.palette.common.mainBg,
				}}
			>
				שלח פנייה
			</Typography>
		</Button>
	);

	const employersFormList = [
		{
			label: "שם החברה",
			setter: setCompanyName,
			value: companyName,
			id: "companyName",
		},
		{
			label: "אימייל",
			setter: setEmailProps,
			value: emailProps.email,
			helper: emailProps.helper,
			id: "email",
		},
		{
			label: "טלפון",
			setter: setPhoneProps,
			value: phoneProps.phone,
			helper: phoneProps.helper,
			id: "phone",
			isLast: true,
		},
	];

	const cardsPagesForSmallerScreens = (
		<Grid container direction="column" className={classes.cardsPage}>
			<Grid item className={classes.cardRightContainer}>
				<Grid container justify="center">
					{!isSent ? (
						<Grid
							item
							container
							alignItems={matchesSM ? undefined : "center"}
							justify="space-between"
							direction="column"
							className={classes.cardContent}
						>
							<Grid item>
								<Typography className={classes.cardHeader}>
									מעסיקים?
								</Typography>
								<Typography
									className={classes.cardFormSubHeader}
								>
									גם עבורכם נמצא את
								</Typography>
								<Typography
									className={classes.cardFormSubHeaderBold}
								>
									<b>העובד האחד</b>
								</Typography>
							</Grid>
							<Grid item>
								<form
									id="employersForm"
									className={classes.employerSendForm}
									noValidate
									autoComplete="off"
									onSubmit={submitCompany}
								>
									<Grid container direction="column">
										{employersFormList.map(
											(formItem, i) => (
												<Grid
													item
													style={{
														marginBottom: "0.5em",
													}}
													key={i}
												>
													<TextField
														id={formItem.id}
														label={formItem.label}
														error={checkError(
															formItem
														)}
														variant="filled"
														style={{
															color: "white",
														}}
														value={formItem.value}
														onChange={(event) =>
															onChange(event)
														}
													/>
												</Grid>
											)
										)}
									</Grid>
								</form>
							</Grid>
							<Grid
								item
								className={classes.sendCvBtnComponentContainer}
							>
								{employeeSendBtn}
							</Grid>
						</Grid>
					) : (
						<Grid
							item
							container
							alignItems={matchesSM ? undefined : "center"}
							justify="space-between"
							direction="column"
							className={classes.cardContentAfterSent}
						>
							<Grid item>
								<Typography
									className={classes.cardHeader}
									style={{ marginBottom: "0.5em" }}
								>
									הטופס נשלח בהצלחה
								</Typography>
								<Typography
									className={classes.cardFormSubHeaderBold}
								>
									נציגינו יצרו עמך קשר
								</Typography>
								<Typography
									className={classes.cardFormSubHeaderBold}
									style={{ lineHeight: "1" }}
								>
									בהקדם
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item className={classes.cardLeftContainer}>
				<Grid container justify="center">
					<Grid
						item
						container
						direction="column"
						justify="space-between"
						className={classes.cardContent}
						style={{ minHeight: "11em", height: "12em" }}
					>
						<Grid item>
							<Typography className={classes.cardHeader}>
								מחפשים עבודה?
							</Typography>
							<Typography className={classes.cardFormSubHeader}>
								גם עבורכם נמצא את
							</Typography>
							<Typography
								className={classes.cardFormSubHeaderBold}
							>
								<b>המשרה האחת</b>
							</Typography>
						</Grid>
						<Grid
							item
							className={classes.sendCvBtnComponentContainer}
						>
							{sendCvBtnComponent}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{matchesSM ? (
				<Grid
					item
					style={{
						height: "1px",
						borderBottom: "1px solid",
						borderColor: theme.palette.common.blueColor,
						marginBottom: "2em",
					}}
				/>
			) : undefined}
		</Grid>
	);

	const cardsPagesForLargeScreens = (
		<Grid container className={classes.cardsPage} justify="space-around">
			<Grid item xs={5} className={classes.cardRightContainer}>
				{!isSent ? (
					<Grid
						container
						alignItems="center"
						justify="space-between"
						direction="column"
						className={classes.cardContent}
					>
						<Grid item>
							<Typography className={classes.cardHeader}>
								מעסיקים?
							</Typography>
							<Typography className={classes.cardFormSubHeader}>
								גם עבורכם נמצא את
							</Typography>
							<Typography
								className={classes.cardFormSubHeaderBold}
							>
								<b>העובד האחד</b>
							</Typography>
						</Grid>
						<Grid item>
							<form
								id="employersForm"
								className={classes.employerSendForm}
								noValidate
								autoComplete="off"
								onSubmit={submitCompany}
							>
								<Grid container direction="column">
									{employersFormList.map((formItem, i) => (
										<Grid
											item
											style={{
												marginBottom: "0.5em",
											}}
											key={i}
										>
											<TextField
												id={formItem.id}
												label={formItem.label}
												error={checkError(formItem)}
												variant="filled"
												style={{
													color: "white",
												}}
												value={formItem.value}
												onChange={(event) =>
													onChange(event)
												}
											/>
										</Grid>
									))}
								</Grid>
							</form>
						</Grid>
						<Grid
							item
							className={classes.sendCvBtnComponentContainer}
						>
							{employeeSendBtn}
						</Grid>
					</Grid>
				) : (
					<Grid
						item
						container
						justify="center"
						className={classes.cardContentAfterSent}
					>
						<Grid item>
							<Typography
								className={classes.cardHeader}
								style={{ marginBottom: "0.5em" }}
							>
								הטופס נשלח בהצלחה
							</Typography>
							<Typography
								className={classes.cardFormSubHeaderBold}
							>
								נציגינו יצרו עמך קשר
							</Typography>
							<Typography
								className={classes.cardFormSubHeaderBold}
								style={{ lineHeight: "1" }}
							>
								בהקדם
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid item xs={5} className={classes.cardLeftContainer}>
				<Grid
					container
					alignItems="center"
					justify="space-between"
					direction="column"
					className={classes.cardContent}
				>
					<Grid item>
						<Typography className={classes.cardHeader}>
							מחפשים עבודה?
						</Typography>
						<Typography className={classes.cardFormSubHeader}>
							גם עבורכם נמצא את
						</Typography>
						<Typography
							className={classes.cardFormSubHeaderBold}
							style={{
								fontSize: "3em",
							}}
						>
							<b>המשרה האחת</b>
						</Typography>
					</Grid>
					<Grid item className={classes.sendCvBtnComponentContainer}>
						{sendCvBtnComponent}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

	return (
		<React.Fragment>
			<Grid container direction="column" className={classes.root}>
				<Grid item>
					<div className={classes.landingPageImage} />
					{matchesSM ? undefined : (
						<a
							href="/"
							style={{
								textDecoration: "none",
							}}
						>
							<img
								src={logo}
								alt="PHR company logo"
								className={classes.logo}
							/>
						</a>
					)}
				</Grid>
				<Grid item>
					{matchesSM
						? cardsPagesForSmallerScreens
						: cardsPagesForLargeScreens}
				</Grid>
				<Grid item>
					<CustomersSlider />
				</Grid>
				<Grid item>
					<JobFields />
				</Grid>
			</Grid>
			<SnackbarComponent
				open={alert.open}
				message={alert.message}
				backgroundColor={alert.backgroundColor}
				setAlert={setAlert}
			/>
		</React.Fragment>
	);
}

import React from "react";
import {
	makeStyles,
	Grid,
	Typography,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import ContactComponent from "../ui/ContactComponent";

import bg1 from "../../assets/bg1.jpg";
import sendCvImage from "../../assets/sendCvImage.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.common.mainBg,
	},
	rightContainer: {
		backgroundImage: `url(${bg1})`,
		backgroundPositionX: "40%",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		maxHeight: "70em",
	},
	title: {
		...theme.typography.pageHeader,
	},
	mainContainer: {
		marginBottom: "3em",
	},
	leftContainer: {
		paddingTop: "2em",
		marginBottom: "3em",
		paddingRight: "1em",
		[theme.breakpoints.down("sm")]: {
			paddingRight: "0",
		},
	},
	paragraphHeader: {
		...theme.typography.subtitle1Header,
		marginTop: "1em",
	},
	paragraph: {
		...theme.typography.subtitle1,
		direction: "ltr",
		lineHeight: "1.5",
	},
	paragraphWithBorderBottom: {
		...theme.typography.subtitle1,
		lineHeight: "1.5",
		"&:hover": {
			borderBottom: "1px solid",
		},
	},
}));
export default function SendCv(props) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Grid container className={classes.root}>
			<Grid
				item
				xs={matchesSM ? 0 : 3}
				className={classes.rightContainer}
			/>
			<Grid item xs={matchesSM ? 12 : 9}>
				<Grid
					container
					direction="column"
					className={classes.leftContainer}
				>
					<Grid item>
						<Typography className={classes.title}>
							{matchesSM ? 'שליחת קו"ח' : "שליחת קורות חיים"}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container justify="center">
							<Grid item>
								<ContactComponent
									isJobRequired={true}
									isCvRequired={true}
									isContactReasonRequired={false}
									header={
										matchesSM
											? "צרו קשר"
											: "צרו קשר עם מומחה השמה"
									}
								/>
							</Grid>
							{!matchesMD && (
								<Grid item>
									<img
										src={sendCvImage}
										alt="send cv"
										style={{
											width: "30em",
										}}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

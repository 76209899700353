import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export default function SocialMediaIcons() {
	const theme = useTheme();

	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

	const icons = [
		{
			link: "https://www.linkedin.com/in/judit-ziter-558b5357/",
			iconComp: (
				<LinkedInIcon
					style={{
						color: "#0277BD",
						fontSize: matchesXS ? "3em" : "4em",
					}}
				/>
			),
		},
		{
			link: "https://www.facebook.com/PHR.HUMAN.RESOURCES/?pnref=story",
			iconComp: (
				<FacebookIcon
					style={{
						color: "#1565C0",
						fontSize: matchesXS ? "3em" : "4em",
					}}
				/>
			),
		},
	];

	return (
		<Grid
			container
			justify="flex-start"
			style={{
				position: "fixed",
				zIndex: theme.zIndex.modal + 1,
				top: matchesXS ? "0.5em" : undefined,
				bottom: "auto",
				width: "8em",
				marginRight: matchesSM ? "5em" : undefined,
			}}
		>
			<Grid item>
				<Grid container direction="row">
					{icons.map((icon, index) => (
						<Grid
							item
							key={index}
							component={"a"}
							href={icon.link}
							rel="noopener noreferrer"
							target="_blank"
						>
							{icon.iconComp}
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
}
